import React from 'react'
import { User, PanelProps } from './types'
import { Button, InfoLabel, Input, Label, makeStyles, shorthands } from '@fluentui/react-components'
import { Drawer } from '@fluentui/react-components/unstable'
import Grid from '@mui/joy/Grid'
import { ShareScreenStop24Filled } from '@fluentui/react-icons'


export interface UserPanelProps extends PanelProps<User> {

}

const useStyles = makeStyles({
	root: {
		// Stack the label above the field
		display: "flex",
		flexDirection: "column",
		// Use 2px gap below the label (per the design system)
		...shorthands.gap("2px"),
		// Prevent the example from taking the full width of the page (optional)
		//maxWidth: "100%",
		fontSize: '10pt'
	},
});

const UserPanel: React.FC<UserPanelProps> = (props) => {
	const user = props.selectedObject
	const [theUser, setTheUser] = React.useState<User | null>(user)

	React.useEffect(() => {
		setTheUser(props.selectedObject)
		//console.log("Updating theUser")
	}, [props])

	//console.log(theUser?.Name || "bit funny, this")



	const styles = useStyles();

	console.log("User in panel is", props.selectedObject)
	return (
		<Drawer open={props.open} position='end' size='medium'>
			<div style={{ padding: '32px', width: '500px' }}>
				<div style={{ display: "flex", justifyContent: "space-between" }}>
					<div><strong>USER</strong></div>
					<div><ShareScreenStop24Filled style={{ cursor: "pointer", color: "red" }} onClick={() => props.onClose()} /></div>
				</div>
				<br /><br /><br />

				<div className={styles.root}>
					<Grid container>
						<Grid xs={3}>
							<Label style={{ fontSize: '8pt', float: 'left' }} htmlFor="orgId">Id</Label>
							<InfoLabel info="The org id cannot be changed" style={{ fontSize: '8pt', float: 'left' }} />
						</Grid>
						<Grid xs={9}>
							<Input appearance='underline' required id="orgId" value={theUser?.UserId || ''} disabled style={{ width: '100%' }} /><br />
						</Grid>
					</Grid>
				</div>

				<div className={styles.root}>
					<Grid container>
						<Grid xs={3}>
							<Label style={{ fontSize: '8pt' }} htmlFor="orgName">Name</Label>
						</Grid>
						<Grid xs={9}>
							<Input appearance='underline' required id="orgName" value={theUser?.Name || ''} onChange={(e, d) => { const user = theUser; if (user) user.Name = d.value; setTheUser(user) }} style={{ width: '100%' }} />
						</Grid>
					</Grid>
				</div>

				<br /><br /><br />

				<Button appearance='primary' onClick={() => props.onClose()}>Close</Button>
			</div>
		</Drawer>
	)
}

export default UserPanel