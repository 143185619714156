export interface BaseObject {
	//id: string
	//Name: string
}

export interface App extends BaseObject {
	AppId: string
	CreatedAt: Date
	Name: string
	OwnerOrgId: string | null
	UpdatedAt: Date
	WebHookSecret: string | null
	WebHookUri: string | null
}

export interface DeviceAlarm extends BaseObject {
	AlarmId: string;
	DeviceId: string;
	FieldId: string;
	Operator: "EQ" | "GT" | "GTE" | "LT" | "LTE" | "NE";
	Target: string;
	CurrValue: string
	OrgId: string;
	State: "Active" | "Clear"
	LastClearedAt: number
	LastFiredAt: number
	CreatedAt: number
	UpdatedAt: number
	DeviceTypeId: string
	LastSeenAt: string
	DeviceName: string
	AlarmName: string
	AlarmDescription: string
	FieldType: string
	DeviceType: string
}

export interface Organization extends BaseObject {
	OrgId: string
	Name: string
	ParentOrgIds: string[]
	ParentNames?: string[]
	CreatedAt: number
	UpdatedAt: number
	DeletedAt: number
}

export interface User extends BaseObject {
	OrgId: string
	UserId: string
	Name: string
	Password: string
	PrimaryEmail: string
	PrimaryPhone: string
	CreatedAt: number
	UpdatedAt: number
	DeletedAt: number
}

export interface Device extends BaseObject {
	OrgId: string
	DeviceId: string
	DeviceTypeId: string
	DeviceTypeName?: string
	LocationId: string
	Location?: string
	Name: string
	Long: number
	Lat: number
	LastSeenAt: string
	CreatedAt: number
	UpdatedAt: number
	DeletedAt: number
}

export interface Plan extends BaseObject {
	PlanId: string
	AppId: string
	OrgId: string
	Name: string
	Description: string
	CreatedAt: number
	UpdatedAt: number
	DeletedAt: number
}

export interface Location extends BaseObject {
	OrgId: string
	LocationId: string
	ParentsLocIds: string[]
	Description: string
	Lat: number
	Long: number
	CreatedAt: number
	UpdatedAt: number
	DeletedAt: number
}

export interface DeviceMetadata {
	OrgId: string
	DeviceTypeId: string
	FieldId: string
	Type: number
	Name: string
	Description: string
	IsTimeSeries: number	// Determines whether the data is in DeviceData (TimeSeries) or DeviceState (State)
	CreatedAt: number
	DeletedAt: number
	TypeName?: string
	Stroke?: string
}

export interface DeviceData {
	OrgId: string
	DeviceId: string
	Data: object
	CreatedAt: string
	CreatedAtTicks?: number
}

export interface DeviceState {
	OrgId: string
	DeviceId: string
	State: object
	CreatedAt: string
}

export interface State {
	Org: Organization | null
	User: User | null
	Device: Device | null
}

export interface PanelProps<T> {
	selectedObject: T | null
	open: boolean
	onClose: (() => void)
	onSave: ((object: T) => void)
}

export interface DeviceDataRecord {
	[key: string]: string | number | Date
}

export interface DeviceEvent {
	Name: string
	OldValue: string
	NewValue: string
	Icon: React.ReactElement
	At: string
}

export interface DeviceType {
	CreatedAt: string
	DeletedAt: string
	Description: string
	DeviceCohortId: string
	DeviceTypeId: string
	UpdatedAt: string
}

export enum MetadataType {
	"String" = 0, "Number", "Enum", "Date"
}

export interface AlarmsFieldQueue {
	FieldId: string
	Value: string
}

export enum Operator {
	"EQ", "NE", "GT", "GTE", "LT", "LTE"
}

export const OperatorText = [
	{ Operator: "EQ", Text: "Equal to", Value: "==" },
	{ Operator: "NE", Text: "Not Equal to", Value: "!=" },
	{ Operator: "GT", Text: "Greater Than", Value: ">" },
	{ Operator: "GTE", Text: "Greater Than or Equal to", Value: ">=" },
	{ Operator: "LT", Text: "Less Than", Value: "<" },
	{ Operator: "LTE", Text: "Less Than or Equal to", Value: "<=" },
]

export interface DeviceAlarms {
	OrgId: string
	AlarmId: string
	DeviceId: string
	FieldId: string
	Operator: Operator
	Value: string
	CreatedAt: string
	UpdatedAt: string
}

export interface AlarmNotificationQueue {
	CreatedAt: string
	GroupId: string
	Message: string
	NotificationId: string
	ProcessedAt: string
	UserId: string
	ChannelId: string
}

export interface AlarmNotifications {
	ChannelId: string
	AlarmId: string
	GroupId: string
	NotificationId: string
	UserId: string
}

export interface NotificationChannels {
	ChannelId: string
	CreatedAt: string
	Description: string
	Name: string
	UpdatedAt: string
	WebHookUri: string
}