import React from 'react'
import { Location, PanelProps } from './types'
import { Button, InfoLabel, Input, Label, makeStyles, shorthands } from '@fluentui/react-components'
import { Drawer } from '@fluentui/react-components/unstable'
import Grid from '@mui/joy/Grid'
import { ShareScreenStop24Filled } from '@fluentui/react-icons'



export interface LocationPanelProps extends PanelProps<Location> {

}

const useStyles = makeStyles({
	root: {
		// Stack the label above the field
		display: "flex",
		flexDirection: "column",
		// Use 2px gap below the label (per the design system)
		...shorthands.gap("2px"),
		// Prevent the example from taking the full width of the page (optional)
		//maxWidth: "100%",
		fontSize: '10pt'
	},
});

const LocationPanel: React.FC<LocationPanelProps> = (props) => {
	const loc = props.selectedObject
	const [theLocation, setTheLocation] = React.useState(loc)

	React.useEffect(() => {
		setTheLocation(props.selectedObject)
	}, [props])

	const styles = useStyles();
	return (
		<Drawer open={props.open} position='end' size='medium' modalType='non-modal'>
			<div style={{ padding: '32px', width: '500px' }}>
			<div style={{ display: "flex", justifyContent: "space-between" }}>
					<div><strong>LOCATION</strong></div>
					<div><ShareScreenStop24Filled style={{ cursor: "pointer", color: "red" }} onClick={() => props.onClose()} /></div>
				</div>
								<br /><br /><br />

				<div className={styles.root}>
					<Grid container>
						<Grid xs={3}>
							<Label style={{ fontSize: '8pt', float: 'left' }} htmlFor="locId">Id</Label>
							<InfoLabel info="The loc id cannot be changed" style={{ fontSize: '8pt', float: 'left' }} />
						</Grid>
						<Grid xs={9}>
							<Input appearance='underline' required id="locId" value={theLocation?.LocationId || ''} disabled style={{ width: '100%' }} /><br />
						</Grid>
					</Grid>
				</div>

				<div className={styles.root}>
					<Grid container>
						<Grid xs={3}>
							<Label style={{ fontSize: '8pt' }} htmlFor="locName">Name</Label>
						</Grid>
						<Grid xs={9}>
							<Input appearance='underline' required id="locName" value={theLocation?.Description || ''} onChange={(e, d) => { setTheLocation({ ...theLocation, Description: d.value } as Location) }} style={{ width: '100%' }} />
						</Grid>
					</Grid>
				</div>

				<br /><br /><br />

				<Button appearance='primary' onClick={() => props.onSave(theLocation as Location)}>Save</Button>
				&nbsp;&nbsp;
				<Button appearance='secondary' onClick={() => props.onClose()}>Close</Button>
			</div>
		</Drawer>
	)
}

export default LocationPanel