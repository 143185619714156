import { Checkbox, Dropdown, Option, Radio, RadioGroup, SelectTabData, SelectTabEvent, Tab, TabList, TabValue, makeStyles, shorthands, tokens } from '@fluentui/react-components'
import { AirplaneRegular, AirplaneFilled, AirplaneTakeOffRegular, AirplaneTakeOffFilled, TimeAndWeatherRegular, TimeAndWeatherFilled, bundleIcon, } from "@fluentui/react-icons"
import React from 'react'

const Airplane = bundleIcon(AirplaneFilled, AirplaneRegular)
const AirplaneTakeOff = bundleIcon(AirplaneTakeOffFilled, AirplaneTakeOffRegular)
const TimeAndWeather = bundleIcon(TimeAndWeatherFilled, TimeAndWeatherRegular)

const useStyles = makeStyles({
	root: {
		alignItems: "flex-start",
		display: "flex",
		flexDirection: "column",
		justifyContent: "flex-start",
		...shorthands.padding("50px", "20px"),
		rowGap: "20px",
		fontSize: "9pt"
	},
	panels: {
		...shorthands.padding(0, "10px"),
		"& th": {
			textAlign: "left",
			...shorthands.padding(0, "30px", 0, 0),
		},
	},
	propsTable: {
		"& td:first-child": {
			fontWeight: tokens.fontWeightSemibold,
		},
		"& td": {
			...shorthands.padding(0, "30px", 0, 0),
		},
	},
});

export const Settings: React.FC = () => {
	const [selectedValue, setSelectedValue] = React.useState<TabValue>("appearance")
	const styles = useStyles();

	const onTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
		setSelectedValue(data.value);
	}

	const Appearance = React.memo(() => (
		<div role="tabpanel" aria-labelledby="Appearance">
			<table>
				<tbody>
					<tr>
						<td style={{ verticalAlign: "top", width: "120px"}}>Theme</td>
						<td style={{ paddingLeft: "8px" }}><Dropdown>
							<Option>Bing</Option>
							<Option>Bong</Option>
							<Option>Blah</Option>
						</Dropdown></td>
					</tr>
					<tr>
						<td style={{ verticalAlign: "top" }}>
							Next Setting
						</td>
						<td>
							<Checkbox />
						</td>
					</tr>
					<tr>
						<td style={{ verticalAlign: "top" }}>Important</td>
						<td>
							<RadioGroup>
								<Radio value="apple" label="Apple" />
								<Radio value="pear" label="Pear" />
								<Radio value="banana" label="Banana" />
								<Radio value="orange" label="Orange" />
							</RadioGroup>
						</td>
					</tr>
				</tbody>
			</table>
		</div >
	));

	const Integrations = React.memo(() => (
		<div role="tabpanel" aria-labelledby="Integrations">
			<table>
				<thead>
					<th>Destination</th>
					<th>Gate</th>
					<th>ETD</th>
				</thead>
				<tbody>
					<tr>
						<td>MSP</td>
						<td>A7</td>
						<td>8:26 AM</td>
					</tr>
					<tr>
						<td>DCA</td>
						<td>N2</td>
						<td>9:03 AM</td>
					</tr>
					<tr>
						<td>LAS</td>
						<td>E15</td>
						<td>2:36 PM</td>
					</tr>
				</tbody>
			</table>
		</div>
	));

	const History = React.memo(() => (
		<div role="tabpanel" aria-labelledby="History">
			<table >
				<tbody>
					<tr>
						<td>Time</td>
						<td>6:45 AM</td>
					</tr>
					<tr>
						<td>Temperature</td>
						<td>68F / 20C</td>
					</tr>
					<tr>
						<td>Forecast</td>
						<td>Overcast</td>
					</tr>
					<tr>
						<td>Visibility</td>
						<td>0.5 miles, 1800 ft runway visual range</td>
					</tr>
				</tbody>
			</table>
		</div>
	));

	return (
		<div className={styles.root}>
			<TabList selectedValue={selectedValue} onTabSelect={onTabSelect}>
				<Tab id="Appearance" icon={<Airplane />} value="appearance">
					Appearance
				</Tab>
				<Tab id="Integrations" icon={<AirplaneTakeOff />} value="integrations">
					Integrations
				</Tab>
				<Tab id="History" icon={<TimeAndWeather />} value="history">
					History
				</Tab>
			</TabList>
			<div style={{ padding: "12px" }}>
				{selectedValue === "appearance" && <Appearance />}
				{selectedValue === "integrations" && <Integrations />}
				{selectedValue === "history" && <History />}
			</div>
		</div>
	)
}