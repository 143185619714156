import { BrandVariants, Theme, createDarkTheme, createLightTheme } from "@fluentui/react-components";
const greenTheme: BrandVariants = {
	10: "#020401",
	20: "#111C0E",
	30: "#172F15",
	40: "#1B3C19",
	50: "#1E4B1D",
	60: "#215921",
	70: "#236825",
	80: "#257828",
	90: "#27882C",
	100: "#289830",
	110: "#28A833",
	120: "#28B936",
	130: "#27CA3A",
	140: "#5CD75C",
	150: "#8AE184",
	160: "#B1EBAA"
};

export const lightTheme: Theme = {
	...createLightTheme(greenTheme),
};

export const darkTheme: Theme = {
	...createDarkTheme(greenTheme),
};


darkTheme.colorBrandForeground1 = greenTheme[110];
darkTheme.colorBrandForeground2 = greenTheme[120];
