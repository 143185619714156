import React from 'react'
import Grid from '@mui/joy/Grid'
import { Link } from "react-router-dom"
import { Button } from '@fluentui/react-components'

const HomeMenu = () => {
	return (
		<div style={{ padding: '32px' }}>
			<Grid container>
				<Grid xs={4}>
					<Button><Link to="/orgs">Manage Organizations</Link></Button>
				</Grid>
				<Grid xs={4}>
					<Button><Link to="/users">Manage Users</Link></Button>
				</Grid>
				<Grid xs={4}>
				<Button><Link to="/devices">Devices</Link></Button>
				</Grid>
				<Grid xs={4}>
				<Button><Link to="/plans">Plans</Link></Button>
				</Grid>
			</Grid>
		</div>
	)
}

export default HomeMenu
