import React from 'react'
import { Plan, PanelProps } from './types'
import { Button, InfoLabel, Input, Label, makeStyles, shorthands } from '@fluentui/react-components'
import { Drawer } from '@fluentui/react-components/unstable'
import Grid from '@mui/joy/Grid'
import { ShareScreenStop24Filled } from '@fluentui/react-icons'



export interface PlanPanelProps extends PanelProps<Plan> {

}

const useStyles = makeStyles({
	root: {
		// Stack the label above the field
		display: "flex",
		flexDirection: "column",
		// Use 2px gap below the label (per the design system)
		...shorthands.gap("2px"),
		// Prevent the example from taking the full width of the page (optional)
		//maxWidth: "100%",
		fontSize: '10pt'
	},
});

const PlanPanel: React.FC<PlanPanelProps> = (props) => {
	const plan = props.selectedObject
	const [thePlan, setThePlan] = React.useState<Plan | null>(plan)

	React.useEffect(() => {
		setThePlan(props.selectedObject)
		//console.log("Updating thePlan")
	}, [props])

	//console.log(thePlan?.Name || "bit funny, this")



	const styles = useStyles();

	console.log("Plan in panel is", props.selectedObject)
	return (
		<Drawer open={props.open} position='end' size='medium'>
			<div style={{ padding: '32px', width: '500px' }}>
				<div style={{ display: "flex", justifyContent: "space-between" }}>
					<div><strong>PLAN</strong></div>
					<div><ShareScreenStop24Filled style={{ cursor: "pointer", color: "red" }} onClick={() => props.onClose()} /></div>
				</div>
				<br /><br /><br />

				<div className={styles.root}>
					<Grid container>
						<Grid xs={3}>
							<Label style={{ fontSize: '8pt', float: 'left' }} htmlFor="orgId">Id</Label>
							<InfoLabel info="The org id cannot be changed" style={{ fontSize: '8pt', float: 'left' }} />
						</Grid>
						<Grid xs={9}>
							<Input appearance='underline' required id="orgId" value={thePlan?.PlanId || ''} disabled style={{ width: '100%' }} /><br />
						</Grid>
					</Grid>
				</div>

				<div className={styles.root}>
					<Grid container>
						<Grid xs={3}>
							<Label style={{ fontSize: '8pt' }} htmlFor="orgName">Name</Label>
						</Grid>
						<Grid xs={9}>
							<Input appearance='underline' required id="orgName" value={thePlan?.Name || ''} onChange={(e, d) => { const plan = thePlan; if (plan) plan.Name = d.value; setThePlan(plan) }} style={{ width: '100%' }} />
						</Grid>
					</Grid>
				</div>

				<br /><br /><br />

				<Button appearance='primary' onClick={() => props.onClose()}>Close</Button>
			</div>
		</Drawer>
	)
}

export default PlanPanel